import React, { Component } from 'react'
import {
  BrowserRouter, Route, Switch
} from 'react-router-dom'
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider'
import createMuiTheme from '@material-ui/core/styles/createMuiTheme'
import ReactGA from 'react-ga'
import { createBrowserHistory } from 'history'
import Main from '../Main'
import Login from '../Login'

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#4cb4d9',
      main: '#0084a7',
      dark: '#0084a7',
      contrastText: '#000000'
    },
    secondary: {
      light: '#0051e5',
      main: '#657dff',
      dark: '#002ab2',
      contrastText: '#ffffff'
    }
  }
})

const history = createBrowserHistory()

class Router extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
    ReactGA.initialize('187975709')
    history.listen(({ pathname }) => {
      ReactGA.set({ page: pathname })
      ReactGA.pageview(pathname)
    })
  }

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <BrowserRouter history={this.state.history}>
          <Route exact component={Login} path='/login' />
          <Switch>
            <Route exact component={Main} path='/' />
          </Switch>
        </BrowserRouter>
      </MuiThemeProvider>
    )
  }
}

export default Router

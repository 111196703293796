import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Snackbar from '@material-ui/core/Snackbar'
import Container from '@material-ui/core/Container'
import { LinearProgress } from '@material-ui/core'
import { withRouter } from 'react-router'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import superagent from 'superagent'
import PropTypes from 'prop-types'
import store from '../Redux/Store'
import { Login } from './action'
import { setCompanyname, setCompanyid } from '../Main/GroupInfo/action'
import WebRTC from '../WebRTC'
import { setUsers } from '../Main/Status/StatusClient/action'


const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
      height: '100%'
    }
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  logo: {
    width: '100%',
    height: '100%'
  },
  title: {
    margin: theme.spacing(1)
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.dark
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  login: {
    margin: theme.spacing(3, 0, 2),
    color: '#fff',
    backgroundColor: theme.palette.secondary.dark
  },
  loginProgress: {
    marginTop: '24px'
  }
})

class LoginPage extends Component {
  constructor(props) {
    document.title = 'ログイン画面 / RISA'
    if (store.getState().webrtc.isTalking) {
      WebRTC.disconnect()
    }
    if (!window.chatclient === undefined) {
      window.chatclient.disconnect()
    }
    super(props)

    this.state = {
      email: '',
      password: '',
      iserror: false,
      message: '',
      logining: false,
      checked: true
    }
    this.handleEmail = this.handleEmail.bind(this)
    this.handlePassword = this.handlePassword.bind(this)
    this.handleLogin = this.handleLogin.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleCheck = this.handleCheck.bind(this)
    this.handleContact = this.handleContact.bind(this)
    store.dispatch(Login(LoginPage.getCookieArray().access_token))
    this.getCompany()
  }

  getCompany() {
    const { history } = this.props
    superagent
      .get(`${process.env.REACT_APP_WEBSERVICE}/api/company`)
      .send()
      .set('Authorization', `Bearer ${store.getState().auth.access_token}`)
      .set('Accept', 'application/json')
      .end((err, res) => {
        if (!err) {
          try {
            const { cid, name, member } = JSON.parse(res.text)
            store.dispatch(setCompanyname(name))
            store.dispatch(setCompanyid(cid))
            store.dispatch(setUsers(member))
            history.push('/')
          } catch (e) {
            console.log(e)
          }
        } else {
          console.log(err)
        }
      })
  }

  static getCookieArray() {
    const arr = []
    if (document.cookie !== '') {
      const tmp = document.cookie.split('; ')
      for (let i = 0; i < tmp.length; i += 1) {
        const data = tmp[i].split('=')
        arr[data[0]] = decodeURIComponent(data[1])
      }
    }
    return arr
  }


  handleLogin() {
    const { email, password } = this.state

    this.setState({ logining: true })
    superagent
      .post(`${process.env.REACT_APP_WEBSERVICE}/oauth/token`)
      .send({
        grant_type: 'password',
        client_id: process.env.REACT_APP_WEBSERVICE_ID,
        client_secret: process.env.REACT_APP_WEBSERVICE_SECRET,
        username: email,
        password: password,
        scope: '*'
      })
      .set('Content-Type', 'application/json')
      .end((err, res) => {
        this.setState({ logining: false })
        if (!err) {
          // eslint-disable-next-line camelcase
          const { access_token, expires_in } = JSON.parse(res.text)
          store.dispatch(Login(access_token))
          // eslint-disable-next-line camelcase
          document.cookie = `access_token=${access_token}; max-age=${expires_in}; secure;`
          this.getCompany()
        } else {
          this.handleOpen('Login Error')
        }
      })
  }


  handleEmail(e) {
    this.setState({ email: e.target.value })
  }

  handlePassword(e) {
    this.setState({ password: e.target.value })
  }

  handleOpen(text) {
    this.setState({ message: text, iserror: true })
  }

  handleClose() {
    this.setState({ iserror: false })
  }

  handleCheck() {
    const { checked } = this.state
    this.setState({ checked: !checked })
  }

  handleContact() {
    const url = `https://forms.gle/2CDtEUvZJ9j7ikm37`
    window.open(url)
  }

  render() {
    const { classes } = this.props
    const {
      message, iserror, logining, email, password, checked
    } = this.state


    return (
      <Container className={classes.container} component='main' maxWidth='xs'>
        <CssBaseline />
        <div className={classes.paper}>
          <source srcSet='logo.webp' type='image/webp' />
          <img alt='logo' className={classes.logo} src='logo.png' />
          <form noValidate className={classes.form}>
            <TextField
              autoFocus
              fullWidth
              autoComplete='email'
              id='email'
              label='メールアドレス'
              margin='normal'
              name='email'
              onChange={this.handleEmail}
              value={email}
              variant='outlined'
            />
            <TextField
              fullWidth
              autoComplete='current-password'
              id='password'
              label='パスワード'
              margin='normal'
              name='password'
              onChange={this.handlePassword}
              onKeyPress={event => {
                if (event.key === 'Enter') {
                  this.handleLogin()
                }
              }}
              type='password'
              value={password}
              variant='outlined'
            />
            <FormControlLabel
              control={<Checkbox checked={checked} color='primary' onChange={this.handleCheck} value='remember' />}
              label='ログイン情報をPCに保存する'
            />
            {logining ? <LinearProgress className={classes.loginProgress} /> : ''}
            {!logining
              ? (
                <Button
                  fullWidth
                  className={classes.login}
                  color='primary'
                  onClick={this.handleLogin}
                  variant='contained'
                >
                ログイン
                </Button>
)
              : ''}
            <Grid container>
              <Grid item xs>
                <Link href={`${process.env.REACT_APP_WEBSERVICE}/password/reset`} variant='body2'>
                  {'パスワードを忘れた方'}
                </Link>
              </Grid>
              <Grid item>
                <Link href={process.env.REACT_APP_WEBSERVICE} variant='body2'>
                  {'管理画面はこちら'}
                </Link>
              </Grid>
            </Grid>
            <div style={{ textAlign: 'center', width: '100%', marginTop: '10px' }}>
              <Link onClick={this.handleContact} variant='body2'>
                {'お問い合わせ'}
              </Link>
            </div>
          </form>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          ContentProps={{
            'aria-describedby': 'message-id'
          }}
          key='bottom,right'
          message={<span id='message-id'>{message}</span>}
          onClose={this.handleClose}
          open={iserror}
        />
      </Container>
    )
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default withRouter(connect()(withStyles(styles)(LoginPage)))

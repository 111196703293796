import { createStore, combineReducers } from 'redux'
import auth from '../../Login/reducers'
import webrtc from '../../WebRTC/reducers'
import chatclient from '../../Main/Chat/ChatClient/reducers'
import groupInfo from '../../Main/GroupInfo/reducers'
import room from '../../Main/Room/reducers'
import statusclient from '../../Main/Status/StatusClient/reducers'
import memberProfile from '../../Main/MemberProfile/reducers'

const rootReducer = combineReducers({
  auth,
  webrtc,
  groupInfo,
  chatclient,
  room,
  statusclient,
  memberProfile
})

// eslint-disable-next-line no-underscore-dangle
const store = createStore(rootReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())

export default store

import io from 'socket.io-client'
import superagent from 'superagent'
import store from '../../../Redux/Store'
import { setStatus, setStatuses } from './action'

let socket

class StatusClient {
  static init() {
    if (store.getState().auth.access_token === undefined) {
      return
    }
    socket = io(`${process.env.REACT_APP_STATUS}`, {
      transports: ['websocket'],
      query: {
        token: store.getState().auth.access_token
      }
    })
    socket.on('connect', () => {
      console.log('connect')
      StatusClient.getStatus()
    })
    socket.on('update', res => {
      const status = JSON.parse(res)
      store.dispatch(setStatus(status))
    })
    socket.on('knock', res => {
      const result = JSON.parse(res)
      if (store.getState().groupInfo.myuserid === result.receiveid) {
        // ここに通知処理
        const fromUserID = result.sendid
        // UUID to UserName
        let fromUser = '[不明]'
        if (store.getState().statusclient.users[fromUserID].nickname) {
          fromUser = store.getState().statusclient.users[fromUserID].nickname
        }
        const notification = new Notification(
          `${fromUser}さんに呼ばれています。`,
          {
            body: `${fromUser}さんに呼ばれています。\nRISAにログインして確認してください。`,
            icon: 'favicon.ico',
            tag: '',
            data: {
              xxx: '任意のデータ'
            }
          }
        )
      }
    })
    socket.on('disconnect', () => {
      console.log('disconnect')
    })
  }

  static disconnect() {
    socket.close()
  }

  static getStatus() {
    superagent
      .get(`${process.env.REACT_APP_STATUS}/statuses?token=${store.getState().auth.access_token}`)
      .send()
      .set('Accept', 'application/json')
      .end((err, res) => {
        if (!err) {
          const statuses = JSON.parse(res.text)
          store.dispatch(setStatuses(statuses))
        }
      })
  }

  static sendStatus(method, param) {
    socket.emit('update', JSON.stringify({
      method: method,
      param: param
    }))
  }

  static sendKnock(sendid) {
    socket.emit('knock', JSON.stringify({
      sendid: sendid
    }))
  }
}

export default StatusClient

import React, { Component } from 'react'
import Unity, { UnityContent } from 'react-unity-webgl'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { LinearProgress } from '@material-ui/core'
import superagent from 'superagent'
import isMobile from 'ismobilejs'
import { compose } from 'redux'
import Navbar from './Navbar'
import store from '../Redux/Store'
import { Logout } from '../Login/action'
import AppBar from './AppBar'
import { setMyNickname, setMyUserid, setMyname } from './GroupInfo/action'
import { setMyUseridStatus } from './Status/StatusClient/action'
import { moveRoom } from './Room/action'
import WebRTC from '../WebRTC'
import StatusClient from './Status/StatusClient'
import Room from './Room'
import SideBar from './SideBar'
import MemberProfile from './MemberProfile'

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  companyname: {
    color: '#fff'
  },
  roomname: {
    paddingLeft: '30px',
    color: '#fff',
    flexGrow: 1
  },
  main: {
    height: 'calc(100vh - 56px)',
    position: 'static',
    width: '100%',
    display: 'flex'
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: '#FFF'
  },
  graphics: {
    mergin: 'center',
    width: '100%',
    height: 'calc(100vh - 56px)'
  },
  screen: {
    mergin: 'center',
    width: '100%',
    height: 'calc(50vh - 56px)'
  },
  screenGraphics: {
    mergin: 'center',
    width: '100%',
    height: '50vh'
  },
  unity: {
    padding: '0 16px'
  },
  border: {
    width: '100%',
    height: '100%',
    borderLeft: 'solid 0.5px #00000066',
    borderRight: 'solid 0.5px #00000066'
  },
  loading: {
    width: '50%',
    height: '30px',
    margin: 'calc((100vh - 56px - 15px) / 2) auto'
  },
  chat: {
    overflow: 'auto'
  },
  textField: {
    height: '8vh',
    width: '100%'
  }
})

class Main extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      progression: 0,
      ready: false
    }
    this.isInput = this.isInput.bind(this)
    this.send = this.send.bind(this)
    this.authUnity = this.authUnity.bind(this)
    this.loadUnity = this.loadUnity.bind(this)
    this.sendMoveRoom = this.sendMoveRoom.bind(this)
    document.title = 'RISA'
    this.getGroupID()
    this.loadUnity()
    // ブラウザが通知をサポートしているか確認する
    if (!('Notification' in window)) {
      alert('通知が未対応のブラウザです')
    } else {
      // 許可を求める
      try {
        Notification.requestPermission()
          .then(permission => {
            if (permission === 'granted') {
              // 許可
            } else if (permission === 'denied') {
              // 拒否
              alert('通知が拒否されました。')
            } else if (permission === 'default') {
              // 無視
              alert('通知が拒否されました。')
            }
          })
      } catch (error) {
        // Safari doesn't return a promise for requestPermissions and it
        // throws a TypeError. It takes a callback as the first argument
        // instead.
        if (error instanceof TypeError) {
          Notification.requestPermission(permission => {
            if (permission === 'granted') {
              // 許可
            } else if (permission === 'denied') {
              // 拒否
              alert('通知が拒否されました。')
            } else if (permission === 'default') {
              // 無視
              alert('通知が拒否されました。')
            }
          })
        } else {
          throw error
        }
      }
    }
  }

  getGroupID() {
    const { history } = this.props
    if (store === undefined || store.getState().auth.access_token === undefined) {
      history.push('/login')
    } else {
      superagent
        .get(`${process.env.REACT_APP_WEBSERVICE}/api/user`)
        .send()
        .set('Authorization', `Bearer ${store.getState().auth.access_token}`)
        .set('Accept', 'application/json')
        .end((err, res) => {
          if (!err) {
            const { uuid, nickname, name } = JSON.parse(res.text)
            store.dispatch(setMyUserid(uuid))
            store.dispatch(setMyUseridStatus(uuid))
            store.dispatch(setMyname(nickname))
            store.dispatch(setMyNickname(name))
            this.setState({ ready: true })
            StatusClient.init()
          } else {
            this.unityContent.remove()
            history.push('/login')
          }
        })
    }
  }

  getScreen() {
    const { classes, webrtc } = this.props

    if (!webrtc.isScreenSharing) {
      return classes.graphics
    }

    return classes.screenGraphics
  }

  loadUnity() {
    this.unityContent = new UnityContent(
      'risa/Build/risa.json',
      'risa/Build/UnityLoader.js', {
        adjustOnWindowResize: true
      }
    )

    this.unityContent.on('progress', progression => {
      this.setState({
        progression: progression,
        iserror: false,
        message: ''
      })
    })

    this.unityContent.on('loaded', () => {
      this.setState({
        isLoading: false
      })
      setInterval(() => {
        this.isInput()
      }, 300)
    })

    this.unityContent.on('Data', json => {
      const data = JSON.parse(json)
      if (data.type === 'RequestAuth') {
        this.authUnity()
      } else if (data.type === 'MoveRoom') {
        this.sendMoveRoom(Number(data.value))
      } else if (data.type === 'LoadOffice') {
        this.sendMoveRoom(store.getState().room.rid)
      }
    })
  }

  authUnity() {
    this.send('REACT_APP_WEBSERVICE', process.env.REACT_APP_WEBSERVICE)
    this.send('AuthToken', store.getState().auth.access_token)
  }

  isInput() {
    if (window.document.activeElement.tagName === 'INPUT' || window.document.activeElement.tagName === 'TEXTAREA') {
      this.send('DisableInput', '')
    } else {
      this.send('EnableInput', '')
    }
  }

  send(type, value) {
    const {
      isLoading
    } = this.state
    const json = {
      type: type,
      value: value
    }
    if (!isLoading) {
      this.unityContent.send('WebGLReceiver', 'OnReceive', JSON.stringify(json))
    }
  }

  sendMoveRoom(index, isUnity = false) {
    store.dispatch(moveRoom(index))
    document.title = `${Room.getRoomName(index)} / RISA`
    WebRTC.reconnect()
    if (isUnity) {
      this.send('MOVEROOM', `${index}`)
    }
  }

  logout() {
    const { history } = this.props
    store.dispatch(Logout())
    history.push('/login')
  }

  render() {
    const { classes, groupInfo } = this.props
    const {
      isLoading, progression, ready
    } = this.state
    return (
      <div className={classes.root}>
        <MemberProfile />
        <AppBar companyname={groupInfo.companyname} />
        <div className={classes.main}>
          <Navbar sendMoveRoom={this.sendMoveRoom} />
          <div container style={{ width: 'calc(100% - 200px)', display: 'flex' }}>
            {!isMobile.phone
              && (
                <div className={classes.border} style={{ width: 'calc(100% - 250px)' }}>
                  <div className={this.getScreen()}>
                    {isLoading === true
                      && (
                        <div className={classes.loading}>
                          <LinearProgress value={progression * 100} variant='determinate' />
                          <div style={{ textAlign: 'center', marginTop: '8px' }}>{`Loading ${Math.ceil(progression * 100)} %...`}</div>
                        </div>
                      )
                    }
                    {ready === true
                      && <Unity className={classes.unity} unityContent={this.unityContent} />
                    }
                    <ul
                      id='peers-video'
                      style={{
                        overflowX: 'auto', whiteSpace: 'nowrap', margin: '0', padding: '0'
                      }}
                    />
                  </div>
                </div>
              )
            }
            <div style={{ width: '250px' }}>
              <SideBar />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Main.propTypes = {
  classes: PropTypes.object.isRequired,
  groupInfo: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  webrtc: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  return {
    groupInfo: state.groupInfo,
    webrtc: state.webrtc
  }
}

export default compose(withStyles(styles), connect(mapStateToProps))(Main)

export const setStatus = status => ({
  type: '@risa/SETSTATUS',
  status: status
})

export const setStatuses = statuses => ({
  type: '@risa/SETSTATUSES',
  statuses: statuses
})

export const setUsers = users => ({
  type : '@risa/SETUSERS',
  users : users
})

export const setMyUseridStatus = myuserid => ({
  type : '@risa/SETMYUSERIDSTATUS',
  myuserid : myuserid
})

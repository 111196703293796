export const call = () => ({
  type: '@risa/CALL'
})

export const end = () => ({
  type: '@risa/END'
})

export const mute = () => ({
  type: '@risa/MUTE'
})

export const unmute = () => ({
  type: '@risa/UNMUTE'
})

export const connect = () => ({
  type: '@risa/CONNECT'
})

export const connectCancel = () => ({
  type: '@risa/CONNECTCANCEL'
})

export const connected = () => ({
  type: '@risa/CONNECTED'
})

export const setgroupToken = grouptoken => ({
  type: '@risa/SETGROUPTOKEN',
  grouptoken: grouptoken
})

export const setmembers = members => ({
  type: '@risa/SETMEMBERS',
  members: members
})

export const startScreen = () => ({
  type: '@risa/STARTSCREEN'
})

export const endScreen = () => ({
  type: '@risa/ENDSCREEN'
})

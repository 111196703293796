import React from 'react'
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Chat from '../Chat'
import ChatInput from '../Chat/ChatInput'

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props

  return (
    <Typography
      aria-labelledby={`full-width-tab-${index}`}
      component='div'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      role='tabpanel'
      {...other}
    >
      <Box p={2} style={{ margin: 0, padding: 0 }}>{children}</Box>
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '250px'
  }
}))

export default function FullWidthTabs() {
  const classes = useStyles()
  const theme = useTheme()
  const [value, setValue] = React.useState(0)

  function handleChange(event, newValue) {
    setValue(newValue)
  }

  function handleChangeIndex(index) {
    setValue(index)
  }

  return (
    <div className={classes.root}>
      <div>
        <Chat />
        <ChatInput isconnect={!false} />
      </div>
    </div>
  )
}

import React, { Component } from 'react'
import {
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Icon, Grid, Snackbar
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { closeModal } from './action'
import store from '../../Redux/Store'
import StatusClient from '../Status/StatusClient'

class MemberProfile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showNotif: false
    }
    this.handleOpenDialog = this.handleOpenDialog.bind(this)
    this.convertRoomIDtoName = this.convertRoomIDtoName.bind(this)
    this.sendNotification = this.sendNotification.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  handleClick() {
    this.setState({ showNotif: true })
  }

  handleClose(event, reason) {
    this.setState({ showNotif: false })
  }

  // eslint-disable-next-line class-methods-use-this
  convertRoomIDtoName(id) {
    const rooms = ['全体', '会議室A', '会議室B', '会議室C']
    return rooms[id]
  }

  // eslint-disable-next-line class-methods-use-this
  sendNotification(id) {
    StatusClient.sendKnock(id)
    this.handleClick()
  }

  handleOpenDialog() {
    this.setState({ open: true })
  }

  render() {
    const { memberProfile } = this.props
    const { showNotif } = this.state

    return (
      <div>
        <Dialog fullWidth='true' onClose={() => store.dispatch(closeModal())} open={memberProfile.isOpen}>
          <DialogTitle id='form-dialog-title'>ユーザー情報</DialogTitle>
          <DialogContent>
            {memberProfile.user
              && (
                <DialogContentText>
                  {/* Profile */}
                  <div style={{ display: 'flex', width: '100%', marginBottom: '10px' }}>
                    <div style={{
                      alignItems: 'center', display: 'flex', padding: '0 8px 5px 5px', borderBottom: 'solid 1px #58B5E5', width: '68px'
                    }}
                    >
                      <Icon style={{ fontSize: '1.3rem' }}>person</Icon>
                      <span style={{ fontSize: '1rem' }}>&nbsp;About</span>
                    </div>
                    <div style={{ paddingBottom: '5px', borderBottom: 'solid 1px rgba(0, 0, 0, 0.54)', width: '100%' }} />
                  </div>
                  <Grid container spacing={0} style={{ marginLeft: '10px' }}>
                    <Grid item style={{ marginBottom: '10px' }} xs={3}>
                      氏名：
                    </Grid>
                    <Grid item xs={9}>
                      {memberProfile.user.name}
                    </Grid>
                    <Grid item style={{ marginBottom: '10px' }} xs={3}>
                      表示名：
                    </Grid>
                    <Grid item xs={9}>
                      {memberProfile.user.nickname}
                    </Grid>
                  </Grid>
                  {/* Status */}
                  <div style={{ display: 'flex', width: '100%', marginBottom: '10px' }}>
                    <div style={{
                      alignItems: 'center', display: 'flex', padding: '0 8px 5px 5px', borderBottom: 'solid 1px #58B5E5', width: '77px'
                    }}
                    >
                      <Icon style={{ fontSize: '1.3rem' }}>play_for_work</Icon>
                      <span style={{ fontSize: '1rem' }}>&nbsp;Status</span>
                    </div>
                    <div style={{ paddingBottom: '5px', borderBottom: 'solid 1px rgba(0, 0, 0, 0.54)', width: '100%' }} />
                  </div>
                  <Grid container spacing={0} style={{ marginLeft: '10px' }}>
                    <Grid item style={{ marginBottom: '10px' }} xs={3}>
                      オンライン：
                    </Grid>
                    <Grid item xs={9}>
                      {memberProfile.user.isOnline && (
                        <div>はい</div>
                      )}
                      {!memberProfile.user.isOnline && (
                        <div>いいえ</div>
                      )}
                    </Grid>
                    {memberProfile.user.isOnline && (
                      <React.Fragment>
                        <Grid item style={{ marginBottom: '10px' }} xs={3}>
                          通話中：
                        </Grid>
                        <Grid item xs={9}>
                          {!memberProfile.user.isTalking && (
                            <div>いいえ</div>
                          )}
                          {memberProfile.user.isTalking && (
                            <div>{this.convertRoomIDtoName(memberProfile.user.room)}</div>
                          )}
                        </Grid>
                        {memberProfile.user.isTalking && (
                          <React.Fragment>
                            <Grid item style={{ marginBottom: '10px' }} xs={3}>
                              画面共有中：
                            </Grid>
                            <Grid item xs={9}>
                              {!memberProfile.user.isShareDisplay && (
                                <div>いいえ</div>
                              )}
                              {memberProfile.user.isShareDisplay && (
                                <div>はい</div>
                              )}
                            </Grid>
                          </React.Fragment>
                        )}
                        <Grid item style={{ marginBottom: '10px' }} xs={3}>
                          集中モード：
                        </Grid>
                        <Grid item xs={9}>
                          {!memberProfile.user.isConcentration && (
                            <div>いいえ</div>
                          )}
                          {memberProfile.user.isConcentration && (
                            <div>はい</div>
                          )}
                        </Grid>
                      </React.Fragment>
                    )}
                  </Grid>
                </DialogContentText>
              )
            }
          </DialogContent>
          <DialogActions>
            <Button color='primary' onClick={() => store.dispatch(closeModal())}>
              閉じる
            </Button>
            <Button color='primary' onClick={() => this.sendNotification(memberProfile.user.uuid)}>
              ノック(呼び出し)
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          autoHideDuration={6000}
          ContentProps={{
            'aria-describedby': 'message-id'
          }}
          message={<span id='message-id'>ノック(呼び出し)を行いました。</span>}
          onClose={this.handleClose}
          open={showNotif}
        />
      </div>
    )
  }
}

MemberProfile.propTypes = {
  memberProfile: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  return {
    memberProfile: state.memberProfile
  }
}

export default connect(mapStateToProps)(MemberProfile)

const initialState = {
  companyname: '',
  companyid : '',
  myname: '',
  mynickname: '',
  myuserid: ''
}

const groupInfo = (state = initialState, action) => {
  switch (action.type) {
  case '@risa/SETCOMPANYNAME':
    return {
      ...state,
      companyname: action.name
    }
  case '@risa/SETCOMPANYID':
    return {
      ...state,
      companyid : action.companyid
    }
  case '@risa/SETMYNAME':
    return {
      ...state,
      myname : action.myname
    }
  case '@risa/SETMYNICKNAME':
    return {
      ...state,
      mynickname : action.mynickname
    }
  case '@risa/SETMYUSERID':
    return {
      ...state,
      myuserid : action.myuserid
    }
  default:
    return state
  }
}

export default groupInfo

import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ChatClient from './ChatClient'
import ChatList from './VariableChatList'

class Chat extends Component {
  constructor(props) {
    super(props)
    this.state = {
      height: 0
    }
    ChatClient.init()
  }

  componentWillMount() {
    this.setState({ height: window.innerHeight - 108 })
    window.addEventListener('resize', () => {
      this.setState({ height: window.innerHeight - 108 })
    })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => { })
  }

  render() {
    const { room, chat } = this.props
    const { height } = this.state

    return (
      <div>
        {room.rid === 0 && <ChatList data={chat.room0} height={height} roomid={room.rid} />}
        {room.rid === 1 && <ChatList data={chat.room1} height={height} roomid={room.rid} />}
        {room.rid === 2 && <ChatList data={chat.room2} height={height} roomid={room.rid} />}
        {room.rid === 3 && <ChatList data={chat.room3} height={height} roomid={room.rid} />}
      </div>
    )
  }
}

Chat.propTypes = {
  chat: PropTypes.object.isRequired,
  room: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  return {
    chat: state.chatclient,
    room: state.room
  }
}

export default connect(mapStateToProps)(Chat)

export const addMessage = (payload, roomid) => ({
  type: '@risa/ADDMESSAGE',
  payload : payload,
  roomid : roomid
})

export const addMessages = (payload, roomid) => ({
  type: '@risa/ADDMESSAGES',
  payload : payload,
  roomid : roomid
})

export const connectChatServer = () => ({
  type: '@risa/CONNECTCHATSERVER'
})

export const disconnectChatServer = () => ({
  type: '@risa/DISCONNECTCHATSERVER'
})

const initialState = {
  islogin : false,
  access_token: undefined
}

const auth = (state = initialState, action) => {
  switch (action.type) {
  case '@risa/LOGIN':
    return {
      ...state,
      islogin : true,
      access_token: action.access_token
    }
  case '@risa/LOGOUT':
    return {
      ...state,
      islogin : false,
      access_token: undefined
    }
  default:
    return state
  }
}

export default auth

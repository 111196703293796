import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { Provider } from 'react-redux'
import { CookiesProvider } from 'react-cookie'
import Router from './Router'
import * as serviceWorker from './serviceWorker'
import store from './Redux/Store'
import Bug from './Bug'

Bug.init()

ReactDOM.render(
  <Provider store={store}>
    <CookiesProvider>
      <Router />
    </CookiesProvider>
  </Provider>, document.getElementById('root')
)

serviceWorker.unregister()

import StatusClient from '../Status/StatusClient'

const initialState = {
  rid : 0
}

const room = (state = initialState, action) => {
  switch (action.type) {
  case '@risa/MOVEROOM':
    StatusClient.sendStatus('room', action.rid)
    return {
      ...state,
      rid: action.rid
    }
  default:
    return state
  }
}

export default room

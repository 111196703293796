export const setCompanyname = name => ({
  type: '@risa/SETCOMPANYNAME',
  name: name
})

export const setCompanyid = companyid => ({
  type: '@risa/SETCOMPANYID',
  companyid: companyid
})

export const setMyname = myname => ({
  type: '@risa/SETMYNAME',
  myname: myname
})

export const setMyNickname = mynickname => ({
  type: '@risa/SETMYNICKNAME',
  mynickname: mynickname
})

export const setMyUserid = myuserid => ({
  type: '@risa/SETMYUSERID',
  myuserid: myuserid
})

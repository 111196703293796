const initialState = {
  isOpen: false,
  user : {}
}

const memberProfile = (state = initialState, action = {}) => {
  switch (action.type) {
  case '@risa/OPENMODAL': {
    return {
      ...state,
      isOpen: true,
      user : action.user
    }
  }
  case '@risa/CLOSEMODAL': {
    return {
      ...state,
      isOpen: false,
      user : {}
    }
  }
  default: {
    return state
  }
  }
}

export default memberProfile

const initialState = {
  isConnect : false,
  currentRoom:[
    {}
  ],
  room0: [
    {
      id:'risa',
      name:'RISA',
      message:'ようこそ！ Risaへ 直近100件のメッセージを表示します。',
      create_time:0
    }
  ],
  room1: [
    {
      id:'risa',
      name:'RISA',
      message:'ようこそ！ こちらは会議室Aです。',
      create_time:0
    }
  ],
  room2: [
    {
      id:'risa',
      name:'RISA',
      message:'ようこそ！ こちらは会議室Bです。',
      create_time:0
    }
  ],
  room3: [
    {
      id:'risa',
      name:'RISA',
      message:'ようこそ！ こちらは会議室Cです。',
      create_time:0
    }
  ]
}


const chatclient = (state = initialState, action = {}) => {
  switch (action.type) {
  case '@risa/ADDMESSAGE': {
    if (action.roomid === 0) {
      return {
        ...state,
        room0: [
          ...state.room0,
          action.payload
        ]
      }
    }
    if (action.roomid === 1) {
      return {
        ...state,
        room1: [
          ...state.room1,
          action.payload
        ]
      }
    }
    if (action.roomid === 2) {
      return {
        ...state,
        room2: [
          ...state.room2,
          action.payload
        ]
      }
    }
    if (action.roomid === 3) {
      return {
        ...state,
        room3: [
          ...state.room3,
          action.payload
        ]
      }
    }
    return state
  }
  case '@risa/ADDMESSAGES': {
    const room = {}
    action.payload.map(x => room.push(x))
    if (action.roomid === 0) {
      return {
        ...state,
        room0: [
          action.payload
        ]
      }
    }
    if (action.roomid === 1) {
      action.payload.map(x => state.room1.push(x))
      return {
        ...state,
        room1: [
          action.payload
        ]
      }
    }
    if (action.roomid === 2) {
      action.payload.map(x => state.room2.push(x))
      return {
        ...state,
        room2: [
          action.payload
        ]
      }
    }
    if (action.roomid === 3) {
      action.payload.map(x => state.room3.push(x))
      return {
        ...state,
        room3: [
          action.payload
        ]
      }
    }
    return state
  }
  case '@risa/CONNECTCHATSERVER': {
    return {
      ...state,
      isConnect: true
    }
  }

  case '@risa/DISCONNECTCHATSERVER': {
    return {
      ...state,
      isConnect: false
    }
  }

  case '@risa/CHATMOVEROOM': {
    if (action.roomid === 0) {
      return {
        ...state,
        currentRoom: state.room0
      }
    }
    if (action.roomid === 1) {
      return {
        ...state,
        currentRoom: state.room1
      }
    }
    if (action.roomid === 2) {
      return {
        ...state,
        currentRoom: state.room2
      }
    }
    if (action.roomid === 3) {
      return {
        ...state,
        currentRoom: state.room3
      }
    }
    return {}
  }
  default: {
    return state
  }
  }
}

export default chatclient

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import InputBase from '@material-ui/core/InputBase'
import IconButton from '@material-ui/core/IconButton'
import { Icon } from '@material-ui/core'
import store from '../../../Redux/Store'
import ChatClient from '../ChatClient'

const useStyles = makeStyles({
  root: {
    padding : '0px',
    margin: '0px',
    display: 'flex',
    alignItems: 'center',
    height:'52px',
    boxShadow: '0px 0px'
  },
  input: {
    marginLeft: 8,
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4
  }
})

export default function ChatInput() {
  const classes = useStyles()
  const [values, setValues] = React.useState({
    message: ''
  })

  const handleChange = key => event => {
    setValues({ [key]: event.target.value })
  }

  const send = () => {
    if (values.message !== '') {
      ChatClient.sendMessage(store.getState().room.rid, values.message)
      setValues({ message: '' })
    }
  }

  return (
    <Paper className={classes.root}>
      <InputBase
        className={classes.input}
        disabled={!store.getState().chatclient.isConnect}
        inputProps={{ 'aria-label': 'bare' }}
        onChange={handleChange('message')}
        onKeyPress={event => {
          if (event.key === 'Enter') {
            send()
          }
        }}
        placeholder='メッセージを入力してみよう！ (Enterで送信)'
        value={values.message}
      />
      <IconButton aria-label='Send' className={classes.iconButton} onClick={() => send()}>
        <Icon>send</Icon>
      </IconButton>
    </Paper>
  )
}

import superagent from 'superagent'
import Peer from 'skyway-js'
import store from '../Redux/Store'
import {
  end, connect, connectCancel, connected, setmembers, startScreen, endScreen
} from './action'
import StatusClient from '../Main/Status/StatusClient'

let room
let localStream
let reconnect = false
let members = []
let isNextScreen = false
let myid
let checkScreenTimer

class WebRTC {
  static request() {
    store.dispatch(connect())
    superagent
      .get(`${process.env.REACT_APP_WEBSERVICE}/api/token`)
      .send()
      .set('Authorization', `Bearer ${store.getState().auth.access_token}`)
      .set('Accept', 'application/json')
      .end((err2, res2) => {
        if (!err2) {
          const { token } = JSON.parse(res2.text)
          myid = `peer${store.getState().groupInfo.myuserid}${new Date().getTime()}`
          superagent
            .post(`${process.env.REACT_APP_WEBSERVICE}/api/skyway/credential`)
            .send({ peerId: myid })
            .set('Authorization', `Bearer ${store.getState().auth.access_token}`)
            .set('Accept', 'application/json')
            .end((err3, res3) => {
              if (!err3) {
                const result = JSON.parse(res3.text)
                WebRTC.init(myid, token, result)
              } else {
                store.dispatch(connectCancel())
              }
            })
        } else {
          store.dispatch(connectCancel())
        }
      })
  }

  static async init(peerid, token, credential) {
    const device = await WebRTC.getDevice()
    if (device === true) {
      // const peer = WebRTC.initPeer(peerid, credential)
      const peer = new Peer(peerid, {
        key: 'cec6ef49-4348-495a-bc76-9dfc698d7d58',
        credential: credential
      })
      peer.on('open', id => {
        if (peer !== null || peer !== undefined) {
          WebRTC.createRoom(peer, token)
        }
      })
    } else if (device === false) {
      // 切断処理
      WebRTC.cancel()
    }
  }

  static cancel() {
    WebRTC.updatePeers()
    store.dispatch(connectCancel())
    store.dispatch(endScreen())
    store.dispatch(end())
    localStream = undefined
    members = []
    WebRTC.disconnect()
  }

  static async getDevice() {
    localStream = await navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: false
      })
      .catch(() => {
        alert('マイク見つかりませんでした。ブラウザのマイク使用を許可するか、マイクを接続してください')
        return false
      })
    if (localStream === undefined) {
      alert('マイク取得できません')
      return false
    }
    if (isNextScreen) {
      isNextScreen = false
      let isError = false
      const screenStream = await navigator.mediaDevices.getDisplayMedia({ video: true }).catch(
        () => {
          isError = true
        }
      )
      if (isError) {
        return false
      }
      const screenTrack = screenStream.getVideoTracks()[0]
      localStream.addTrack(screenTrack)
      store.dispatch(startScreen())
      StatusClient.sendStatus('isShareDisplay', true)
      checkScreenTimer = setInterval(() => {
        WebRTC.checkScreenShareOff()
      }, 500)
      return true
    }

    StatusClient.sendStatus('isShareDisplay', false)

    return true
  }

  static async sharescreen() {
    isNextScreen = true
    WebRTC.reconnect()
  }

  static switchMute() {
    localStream.getAudioTracks()[0].enabled = !localStream.getAudioTracks()[0].enabled
    StatusClient.sendStatus('isMicMute', !localStream.getAudioTracks()[0].enabled)
  }

  static updatePeers() {
    try {
      let result = []
      room.members.map(member => result.push(member.slice(0, -13)))
      members.map(member => result.push(member.slice(0, -13)))
      result = Array.from(new Set(result))
      store.dispatch(setmembers(result))
      return result
    } catch (e) {
      console.error(e)
      return []
    }
  }

  static createRoom(peer, token) {
    try {
      WebRTC.disconnect()
      room = peer.joinRoom(`${token + store.getState().room.rid.toString()}`, {
        mode: 'sfu',
        stream: localStream
      })
    } catch (err) {
      console.error(err)
      alert('通話に参加できませんでした。\nネットワーク環境によってはご利用頂けない場合があります。\n一度こちらから接続性のテストを行ってみてください。\nhttps://test.webrtc.org/')
      return
    }

    room.once('open', () => {
      console.log(`Room: ${token + store.getState().room.rid.toString()}に入室しました`)
      store.dispatch(connected())
      WebRTC.updatePeers()
    })

    room.on('peerJoin', peerId => {
      console.log(`${peerId}が入室されました`)
      WebRTC.updatePeers()
    })

    room.on('stream', async stream => {
      if (stream.getVideoTracks()[0] !== undefined) {
        store.dispatch(startScreen())
        const newVideo = document.createElement('video')
        newVideo.srcObject = stream
        newVideo.setAttribute('data-peer-id', stream.peerId)
        newVideo.className = 'videoscreen'
        newVideo.style.height = 'calc(50vh - 90px)'
        newVideo.onclick = WebRTC.startFullscreen
        const peers = document.getElementById('peers-video')
        peers.append(newVideo)
        await newVideo.play().catch(console.error)
        console.log(`${stream.peerId}の画面共有を受信します`)
      }
      const newAudio = document.createElement('audio')
      newAudio.srcObject = stream
      newAudio.setAttribute('data-peer-id', stream.peerId)
      const peers = document.getElementById('peers')
      peers.append(newAudio)
      await newAudio.play().catch(console.error)
      console.log(`${stream.peerId}の音声を受信します`)
      members.push(stream.peerId)
      WebRTC.updatePeers()
    })

    room.on('peerLeave', peerId => {
      members = members.filter(n => n !== peerId)
      WebRTC.updatePeers()

      try {
        const peersvideo = document.getElementById('peers-video')
        const remoteVideo = peersvideo.querySelector(
          `[data-peer-id=${peerId}]`
        )
        if (remoteVideo === undefined) {
          console.log(`video undefined: ${peerId}`)
        } else {
          WebRTC.endFullscreen(remoteVideo)
          if (remoteVideo.srcObject !== null) {
            remoteVideo.srcObject.getTracks().forEach(track => track.stop())
            remoteVideo.srcObject = null
          }
          remoteVideo.remove()
        }
      } catch (e) {
        console.error(e)
      }
      try {
        const peers = document.getElementById('peers')
        const remoteAudio = peers.querySelector(
          `[data-peer-id=${peerId}]`
        )
        if (remoteAudio === undefined) {
          console.log(`audio undefined: ${peerId}`)
        } else {
          if (remoteAudio.srcObject !== null) {
            remoteAudio.srcObject.getTracks().forEach(track => track.stop())
            remoteAudio.srcObject = null
          }
          remoteAudio.remove()
        }
      } catch (e) {
        console.error(e)
      }
      console.log(`${peerId}が退出されました`)
    })

    room.once('error', e => {
      console.error(e)
    })

    room.once('close', () => {
      const peers = document.getElementById('peers')
      Array.from(peers.children).forEach(remoteAudio => {
        remoteAudio.srcObject.getTracks().forEach(track => track.stop())
        remoteAudio.srcObject = null
        remoteAudio.remove()
      })
      const vpeers = document.getElementById('peers-video')
      Array.from(vpeers.children).forEach(remoteVideo => {
        WebRTC.endFullscreen(remoteVideo)
        remoteVideo.srcObject.getTracks().forEach(track => track.stop())
        remoteVideo.srcObject = null
        remoteVideo.remove()
      })
      WebRTC.updatePeers()
      store.dispatch(endScreen())
      store.dispatch(end())
      room = undefined
      members = []
      console.log('退出しました')
      if (reconnect === true) {
        WebRTC.request()
        reconnect = false
      }
    })

    WebRTC.screenMyself()
  }

  static async screenMyself() {
    if (localStream.getVideoTracks()[0] !== undefined) {
      store.dispatch(startScreen())
      const newVideo = document.createElement('video')
      newVideo.srcObject = localStream
      newVideo.setAttribute('data-peer-id', myid)
      newVideo.className = 'videoscreen'
      newVideo.style.height = 'calc(50vh - 90px)'
      newVideo.onclick = WebRTC.startFullscreen
      newVideo.muted = true
      const peers = document.getElementById('peers-video')
      peers.append(newVideo)
      await newVideo.play()
    }
  }

  static checkScreenShareOff() {
    if (localStream.getVideoTracks()[0] === undefined) {
      clearInterval(checkScreenTimer)
    } else if (localStream.getVideoTracks()[0].onended) {
      WebRTC.disconnect()
    }
  }

  static startFullscreen(e) {
    const videlm = e.target
    if (videlm.requestFullScreen) {
      videlm.requestFullScreen()
    } else if (videlm.webkitRequestFullScreen) {
      videlm.webkitRequestFullScreen()
    } else if (videlm.webkitEnterFullscreen) {
      videlm.webkitEnterFullscreen()
    } else if (videlm.mozRequestFullScreen) {
      videlm.mozRequestFullScreen()
    } else if (videlm.msRequestFullscreen) {
      videlm.msRequestFullscreen()
    }
  }

  static endFullscreen(videlm) {
    if (videlm.exitFullscreen) {
      videlm.exitFullscreen()
    } else if (videlm.cancelFullScreen) {
      videlm.cancelFullScreen()
    } else if (videlm.mozCancelFullScreen) {
      videlm.mozCancelFullScreen()
    } else if (videlm.webkitCancelFullScreen) {
      videlm.webkitCancelFullScreen()
    } else if (videlm.msExitFullscreen) {
      videlm.msExitFullscreen()
    }
  }

  static disconnect() {
    if (room !== undefined) {
      room.close()
    }
  }

  static reconnect() {
    if (room !== undefined) {
      reconnect = true
      WebRTC.disconnect()
    }
  }
}


export default WebRTC

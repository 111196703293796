import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import {
  Menu, MenuItem, IconButton, Toolbar, Typography, Button, Icon, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions
} from '@material-ui/core'
import MAppBar from '@material-ui/core/AppBar'
import { AccountCircle } from '@material-ui/icons'
import Room from '../Room'
import store from '../../Redux/Store'

const styles = theme => ({
  AppBar: {
    backgroundColor: '#3f51b5',
    minHeight: '56px'
  },
  companyname: {
    color: '#fff'
  },
  roomname: {
    marginLeft: theme.spacing(3),
    color: '#fff',
    flexGrow: 1
  },
  username: {
    color: '#fff'
  },
  avatoricon: {
    color: '#fff'
  },
  nickname: {
    flexGrow: 1
  }
})

class AppBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      anchorEl: null,
      isFormOpen: false
    }
    this.handleMenuOpen = this.handleMenuOpen.bind(this)
    this.handleMenuClose = this.handleMenuClose.bind(this)
    this.handleRedirect = this.handleRedirect.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
    this.handleFormDialogOpen = this.handleFormDialogOpen.bind(this)
    this.handleFormDialogClose = this.handleFormDialogClose.bind(this)
    this.handleFormApply = this.handleFormApply.bind(this)
  }

  handleMenuOpen(event) {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleMenuClose() {
    this.setState({ anchorEl: null })
  }

  handleLogout() {
    this.handleMenuClose()
    document.cookie = 'access_token=; max-age=0; secure;'
    document.location.href = `${process.env.REACT_APP_APP}`
  }

  handleRedirect() {
    this.handleMenuClose()
    window.open(`${process.env.REACT_APP_WEBSERVICE}`, '_blank')
  }

  handleFormDialogOpen() {
    this.setState({ isFormOpen: true })
  }

  handleFormDialogClose() {
    this.setState({ isFormOpen: false })
  }

  handleFormApply() {
    // Open URL
    const URL = `https://forms.gle/2CDtEUvZJ9j7ikm37`
    window.open(URL)
    // Close Dialog
    this.handleFormDialogClose()
  }

  render() {
    const {
      classes, companyname, room, groupInfo
    } = this.props
    const { anchorEl, isFormOpen } = this.state
    return (
      <React.Fragment>
        <MAppBar className={classes.AppBar} position='static'>
          <Toolbar style={{ minHeight: '56px' }}>
            <Typography className={classes.companyname} variant='h6'>
              {companyname}
            </Typography>
            <Typography className={classes.roomname} variant='h7'>
              {Room.getRoomName(room.rid)}
            </Typography>
            <Button onClick={this.handleFormDialogOpen} style={{ color: '#FFFFFF', border: 'solid 1px #FFFFFF', marginRight: '16px' }} variant='outlined'>
              <Icon style={{ fontSize: '16px' }}>mail</Icon>
              &nbsp;お問い合わせ
            </Button>
            <Typography className={classes.username} variant='h7'>
              {groupInfo.mynickname}
            </Typography>
            <div>
              <IconButton
                aria-controls='menu-appbar'
                aria-haspopup='true'
                aria-label='Account of current user'
                aria-owns={anchorEl ? 'menu' : null}
                className={classes.avatoricon}
                color='inherit'
                onClick={this.handleMenuOpen}
              >
                <AccountCircle />
              </IconButton>
              <Menu
                keepMounted
                anchorEl={anchorEl}
                onClose={this.handleMenuClose}
                open={Boolean(anchorEl)}
              >
                <MenuItem onClick={this.handleRedirect}>管理画面</MenuItem>
                <MenuItem onClick={this.handleLogout}>ログアウト</MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </MAppBar>
        <Dialog aria-labelledby='form-dialog-title' onClose={this.handleFormDialogOpen} open={isFormOpen}>
          <DialogTitle id='form-dialog-title'>お問い合わせ</DialogTitle>
          <DialogContent>
            <DialogContentText>
              お問い合わせを行うには、「問い合わせる」をクリックします。
            </DialogContentText>
            <DialogContentText>
              <a href="https://risa.ne.jp/questions.html" target="_blank">よくあるご質問</a>
            </DialogContentText>
            <DialogContentText>
              ※別タブにてGoogleFormが開きます。
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color='primary' onClick={this.handleFormDialogClose}>
              やめる
            </Button>
            <Button color='primary' onClick={this.handleFormApply}>
              問い合わせる
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}

AppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  companyname: PropTypes.string.isRequired,
  groupInfo: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  room: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  return {
    room: state.room,
    groupInfo: state.groupInfo
  }
}

export default withRouter(connect(mapStateToProps)(withStyles(styles)(AppBar)))

import io from 'socket.io-client'
import {
  addMessage, connectChatServer, disconnectChatServer
} from './action'
import store from '../../../Redux/Store'

let socket

class ChatClient {
  static init() {
    if (store.getState().auth.access_token === undefined) {
      return
    }
    socket = io(`${process.env.REACT_APP_API_CHAT}`, {
      transports: ['websocket'],
      query: {
        token : store.getState().auth.access_token
      }
    })
    socket.on('OK', () => {
      ChatClient.get('0')
      ChatClient.get('1')
      ChatClient.get('2')
      ChatClient.get('3')
      store.dispatch(connectChatServer())
      socket.emit('members')
    })
    socket.on('history:0', messages => {
      messages.forEach(message => {
        message.name = ChatClient.useridTonickname(message.userid)
        store.dispatch(addMessage(message, 0))
      })
    })
    socket.on('history:1', messages => {
      messages.forEach(message => {
        message.name = ChatClient.useridTonickname(message.userid)
        store.dispatch(addMessage(message, 1))
      })
    })
    socket.on('history:2', messages => {
      messages.forEach(message => {
        message.name = ChatClient.useridTonickname(message.userid)
        store.dispatch(addMessage(message, 2))
      })
    })
    socket.on('history:3', messages => {
      messages.forEach(message => {
        message.name = ChatClient.useridTonickname(message.userid)
        store.dispatch(addMessage(message, 3))
      })
    })
    socket.on('message', message => {
      message.name = ChatClient.useridTonickname(message.userid)
      store.dispatch(addMessage(message, message.rid))
    })
    socket.on('disconnect', () => {
      store.dispatch(disconnectChatServer())
    })
  }

  static disconnect() {
    socket.close()
  }

  static useridTonickname(userid) {
    let result
    Object.keys(store.getState().statusclient.users).map(key => store.getState().statusclient.users[key]).map(user => {
      const { name, nickname } = user
      if (userid === user.uuid) {
        // eslint-disable-next-line eqeqeq
        if (user.nickname == undefined) {
          result = name
        } else {
          result = nickname
        }
      }
      return null
    })
    return result
  }

  static get(rid) {
    socket.emit('get', {
      rid: rid
    })
  }

  static sendMessage(rid, message) {
    socket.emit('message', {
      rid: rid,
      message:message
    })
  }
}

export default ChatClient

const initialState = {
  users: {},
  myuserid : ''
}

const sort = users => {
  const online = {}
  const offline = {}
  Object.keys(users).map(key => {
    if (users[key].isOnline) {
      online[users[key].uuid] = users[key]
    } else {
      offline[users[key].uuid] = users[key]
    }
    return null
  })
  return Object.assign(online, offline)
}

const statusclient = (state = initialState, action = {}) => {
  switch (action.type) {
  case '@risa/SETSTATUS': {
    const temp = state.users
    Object.assign(temp[action.status.uuid], action.status)
    const result = sort(temp)
    return {
      ...state,
      users: result
    }
  }
  case '@risa/SETSTATUSES': {
    const temp = state.users
    action.statuses.map(user => {
      Object.assign(temp[user.uuid], user)
      return 0
    })
    const result = sort(temp)

    return {
      ...state,
      users: result
    }
  }

  case '@risa/SETUSERS': {
    const temp = {}
    action.users.map(user => {
      user.isOnline = false
      temp[user.uuid] = user
      return 0
    })
    const result = sort(temp)
    return {
      ...state,
      users: result
    }
  }

  case '@risa/SETMYUSERIDSTATUS': {
    return {
      ...state,
      myuserid: action.myuserid
    }
  }

  default: {
    return state
  }
  }
}

export default statusclient

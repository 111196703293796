import StatusClient from '../Main/Status/StatusClient'

const initialState = {
  mypeerid: undefined,
  isTalking: false,
  token: undefined,
  connecting: false,
  members: [],
  isScreenSharing: false
}

const webrtc = (state = initialState, action) => {
  switch (action.type) {
  case '@risa/SETMYPEERID':
    return {
      ...state,
      mypeerid : action.peerid
    }
  case '@risa/END':
    StatusClient.sendStatus('isTalking', false)
    return {
      ...state,
      isTalking : false,
      isScreenSharing : false
    }
  case '@risa/SETGROUPTOKEN':
    return {
      ...state,
      token: action.grouptoken
    }
  case '@risa/CONNECT':
    StatusClient.sendStatus('isTalking', false)
    return {
      ...state,
      connecting: true
    }
  case '@risa/CONNECTCANCEL':
    StatusClient.sendStatus('isTalking', false)
    return {
      ...state,
      connecting: false,
      isScreenSharing : false
    }
  case '@risa/CONNECTED':
    StatusClient.sendStatus('isTalking', true)
    return {
      ...state,
      connecting: false,
      isTalking : true
    }
  case '@risa/SETMEMBERS':
    return {
      ...state,
      members : action.members
    }
  case '@risa/STARTSCREEN':
    return {
      ...state,
      isScreenSharing : true
    }
  case '@risa/ENDSCREEN':
    return {
      ...state,
      isScreenSharing : false
    }
  default:
    return state
  }
}

export default webrtc

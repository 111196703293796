import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { VariableSizeList } from 'react-window'
import dompurify from 'dompurify'

class ChatList extends Component {
  static setStyle(ele, styles) {
    Object.keys(styles).forEach(key => {
      ele.style[key] = styles[key]
    })
  }

  static calcStrHeight(data) {
    const ele = document.createElement('div')
    ele.id = 'calc'
    ele.style.width = '250px'

    let dom = '<div>'
    dom += '<h4 style="color: #2C2D30; font-family: lato; font-size: 15px; padding-left: 5px; top: -5%; text-align: left; margin: 0;">'
    dom += data.name
    dom += '<span style="color: #2C2D30; padding-left: 5px;font-size: 11px; opacity: 0.6;">'
    dom += ChatList.unixTodate(data.create_date)
    dom += '</span></h4>'
    dom += '<div style="font-size: 15px; padding: 0px 5px;text-align: left;margin: 0;'
    dom += 'padding-bottom: 9px; word-break: break-all; word-wrap: break-word; font-family: Roboto, Helvetica, Arial, sans-serif;"/>'
    dom += ChatList.urlConverter(ChatList.stripTags(data.message))
    dom += '</div></div>'

    ele.innerHTML = dom
    document.body.appendChild(ele)
    const eleHeight = ele.getBoundingClientRect().height
    document.body.removeChild(ele)
    return eleHeight
  }

  static unixTodate(time) {
    if (time === undefined) {
      return ''
    }
    const date = new Date(time)
    const min = `0${date.getMinutes()}`
    return `${date.getHours()}:${min.substr(-2)}`
  }


  static stripTags(input) {
    const tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi
    return input.replace(tags, '')
  }

  static urlConverter(input) {
    const regExp = /(https?:\/\/\S+)/g
    return input.replace(regExp, '<a href="$1" target="_blank">$1</a>')
  }

  constructor(props) {
    super(props)
    this.listRef = React.createRef()
    this.row = this.row.bind(this)
    this.getItemSize = this.getItemSize.bind(this)
  }

  componentDidMount() {
    const { data } = this.props
    this.scrollItem(data.length)
  }

  componentWillUpdate(nextProps) {
    this.scrollItem(nextProps.data.length)
  }

  componentDidUpdate(nextProps) {
    this.scrollItem(nextProps.data.length)
  }

  getItemSize(index) {
    const { data } = this.props
    let height = ChatList.calcStrHeight(data[index])

    // Date list
    if (data[index - 1] !== undefined) {
      const oldTime = data[index - 1].create_date
      const newTime = data[index].create_date
      if (oldTime === undefined || newTime === undefined) {
        // return height
      }
      const oldDate = new Date(oldTime)
      const newDate = new Date(newTime)
      if (oldDate.getDate() !== newDate.getDate()) {
        height += 29
      }
    }
    return height
  }

  scrollItem(index) {
    this.listRef.current.scrollToItem(index, 'smart')
  }

  row({ index, style }) {
    const { data } = this.props

    // Add a hook to make all links open a new window
    dompurify.addHook('afterSanitizeAttributes', node => {
      // set all elements owning target to target=_blank
      if ('target' in node) {
        node.setAttribute('target', '_blank')
      }
      // set non-HTML/MathML links to xlink:show=new
      if (!node.hasAttribute('target')
        && (node.hasAttribute('xlink:href')
          || node.hasAttribute('href'))) {
        node.setAttribute('xlink:show', 'new')
      }
    })

    // If message is undefined;
    if (data[index].message === undefined) {
      return (<span />)
    }

    // Date list
    let dataChange
    let changedDate = ''
    if (data[index - 1] !== undefined) {
      const oldTime = data[index - 1].create_date
      const newTime = data[index].create_date
      if (oldTime === undefined) {
        const newDate = new Date(newTime)
        dataChange = true
        changedDate = `${newDate.getFullYear()}/${newDate.getMonth() + 1}/${newDate.getDate()}`
      } else {
        const oldDate = new Date(oldTime)
        const newDate = new Date(newTime)
        if (oldDate.getDate() !== newDate.getDate()) {
          dataChange = true
          changedDate = `${newDate.getFullYear()}/${newDate.getMonth() + 1}/${newDate.getDate()}`
        } else {
          dataChange = false
        }
      }
    }

    return (
      <div style={style}>
        {dataChange ? (
          <div style={{ width: '250px', textAlign: 'center', paddingBottom: '5px' }}>
            --
            {changedDate}
            {' '}
            --
          </div>
        ) : ''}
        <h4 style={{
          color: '#2C2D30',
          fontFamily: 'lato',
          fontSize: 15,
          paddingLeft: '5px',
          top: '-5%',
          textAlign: 'left',
          margin: '0'
        }}
        >
          {data[index].name}
          <span style={{
            color: '#2C2D30',
            paddingLeft: 5,
            fontSize: 11,
            opacity: 0.8
          }}
          >
            {ChatList.unixTodate(data[index].create_date)}
          </span>
        </h4>
        <div
          dangerouslySetInnerHTML={{
            __html: dompurify.sanitize(ChatList.urlConverter(ChatList.stripTags(data[index].message)))
          }}
          style={{
            color: '#2C2D30',
            fontSize: 15,
            opacity: 1,
            padding: '0px 5px 9px 5px',
            width: '240px',
            textAlign: 'left',
            margin: '0',
            wordBreak: 'break-all',
            overflowWrap: 'break-word',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif;'
          }}
        />
      </div>
    )
  }

  render() {
    const {
      data, height
    } = this.props
    return (
      <div>
        <VariableSizeList
          useIsScrolling
          height={height}
          itemCount={data.length}
          itemSize={this.getItemSize}
          ref={this.listRef}
          width='267px'
        >
          {this.row}
        </VariableSizeList>
      </div>
    )
  }
}

ChatList.propTypes = {
  data: PropTypes.array.isRequired,
  height: PropTypes.any,
  roomid: PropTypes.any,
  width: PropTypes.any
}

export default ChatList

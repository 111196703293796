import StackTrace from 'stacktrace-js'

class Bug {
  static init() {
    window.onerror = Bug.stackerror
  }

  static stackerror(msg, file, line, col, error) {
    StackTrace.fromError(error).then(Bug.callback).catch(Bug.errback)
  }

  static callback(stackframes) {
    const stringifiedStack = stackframes.map(sf => sf.toString()).join('\n')
    console.error(stringifiedStack)
  }

  static errback(err) {
    console.error(err.message)
  }
}

export default Bug

import React, { Component } from 'react'
import { compose } from 'redux'
import {
  List, Divider, ListItem, Tooltip, IconButton, Button
} from '@material-ui/core'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Icon from '@material-ui/core/Icon'
import PropTypes from 'prop-types'
import WebRTC from '../../WebRTC'
import Room from '../Room/index'
import store from '../../Redux/Store'
import { openModal, closeModal } from '../MemberProfile/action'

const styles = theme => ({
  bar: {
    height: '100%',
    width: 200,
    fontWeight: '500'
  },
  channel: {
    height: '5%'
  },
  divider: {

  },
  iconbutton: {

  },
  placeListItemTitle: {
    paddingTop: '4px',
    paddingBottom: '6px',
    fontSize: '18px'
  },
  placeListItemContents: {
    paddingTop: '6px',
    paddingBottom: '6px',
    fontSize: '16px'
  },
  userList: {
    height: 'calc(100vh - 56px - 197px - 48px - 37px - 32px)',
    overflow: 'scroll',
    overflowX: 'hidden',
    width: '100%'
  },
  userListItemTitle: {
    paddingTop: '4px',
    paddingBottom: '6px',
    fontSize: '18px'
  },
  userListItemContents: {
    paddingTop: '6px',
    paddingBottom: '6px',
    fontSize: '16px'
  },
  userListItemIsOnline: {
    fontSize: '12px',
    color: 'rgba(0,0,0,0.6)'
  },
  userListItemName: {

  },
  actions: {

  }
})

class NavBar extends Component {
  static handleCall() {
    WebRTC.request()
  }

  static handleCallCancel() {
    WebRTC.cancel()
  }

  static handleSharescreen() {
    WebRTC.sharescreen()
  }

  constructor(props) {
    super(props)
    this.state = {
      isMute: false,
      backgroundColor: '#FAFAFA',
      color: '#1C1C1C'
    }
    this.handleSwitchMute = this.handleSwitchMute.bind(this)
    this.handleDisconnect = this.handleDisconnect.bind(this)
    this.screenshare = this.screenshare.bind(this)
    this.handleChangeTheme = this.handleChangeTheme.bind(this)
  }

  handleSwitchMute() {
    const { isMute } = this.state
    WebRTC.switchMute()
    this.setState({ isMute: !isMute })
  }

  handleDisconnect() {
    WebRTC.disconnect()
    WebRTC.isScreen = false
    this.setState({ isMute: false })
  }

  handleListItemClick(event, index) {
    const { sendMoveRoom } = this.props
    sendMoveRoom(index, true)
  }

  handleChangeTheme() {
    const { backgroundColor } = this.state
    const { color } = this.state
    if (backgroundColor === '#FAFAFA' && color === '#1C1C1C') {
      // set dark
      this.setState({ backgroundColor: '#363636', color: '#FFFFFF' })
    } else {
      // set white
      this.setState({ backgroundColor: '#FAFAFA', color: '#1C1C1C' })
    }
  }

  screenshare() {
    const { classes } = this.props
    const { isMute } = this.state

    return (
      <div>
        <Button className={classes.iconbutton} onClick={this.handleDisconnect} style={{ margin: '6px' }} variant='outlined'>
          切断
        </Button>
        <Tooltip placement='top' title='ミュート'>
          <IconButton aria-label='mute' className={classes.iconbutton} onClick={this.handleSwitchMute}>
            {isMute ? <Icon>mic_off</Icon> : <Icon>mic</Icon>}
          </IconButton>
        </Tooltip>
      </div>
    )
  }

  talking() {
    const { classes } = this.props
    const { isMute, color } = this.state
    return (
      <div>
        <Button className={classes.iconbutton} onClick={this.handleDisconnect} style={{ margin: '6px' }} variant='outlined'>
          切断
        </Button>
        <Tooltip placement='top' title='ミュート'>
          <IconButton aria-label='mute' className={classes.iconbutton} onClick={this.handleSwitchMute} style={{ color: color }}>
            {isMute ? <Icon>mic_off</Icon> : <Icon>mic</Icon>}
          </IconButton>
        </Tooltip>
        <Tooltip placement='top' title='画面共有'>
          <IconButton aria-label='画面共有' className={classes.iconbutton} onClick={NavBar.handleSharescreen} style={{ color: color }}>
            <Icon>screen_share</Icon>
          </IconButton>
        </Tooltip>
      </div>
    )
  }

  static getTalkStatus(user) {
    if (user.isOnline) {
      if (user.isShareDisplay && user.isTalking) {
        return (<Icon>screen_share</Icon>)
      }
      if (user.isMicMute && user.isTalking) {
        return (<Icon>mic_off</Icon>)
      }
      if (user.isTalking) {
        return (<Icon>call</Icon>)
      }
    }
    return ''
  }

  handleOpenModal(user) {
    store.dispatch(openModal(user))
  }

  render() {
    const {
      classes, webrtc, room, statusclient
    } = this.props

    const { backgroundColor, color } = this.state

    return (
      <div className={classes.bar} role='presentation' style={{ backgroundColor: backgroundColor, color: color }}>
        <div className={classes.actions}>
          <div style={{ display: 'flex' }}>
            {(() => {
              if (webrtc.isTalking) {
                if (webrtc.isScreenSharing) {
                  return this.screenshare()
                }
                return this.talking()
              }
              if (webrtc.connecting) {
                return (
                  <div>
                    <Tooltip onClick={NavBar.handleCallCancel} placement='top' title='キャンセル'>
                      <IconButton aria-label='Call' className={classes.iconbutton} style={{ color: color }}>
                        <Icon>call_end</Icon>
                      </IconButton>
                    </Tooltip>
                    {'接続中...'}
                  </div>
                )
              }
              if (!webrtc.connecting) {
                return (
                  <div>
                    <Button className={classes.iconbutton} onClick={NavBar.handleCall} style={{ margin: '6px' }} variant='outlined'>
                      <Icon style={{ fontSize: '16px' }}>call</Icon>
                        &nbsp;通話
                    </Button>
                  </div>
                )
              }
              return null
            })()}
            {/*
            <div style={{ margin: '0 0 0 auto' }}>
              <Tooltip onClick={this.handleChangeTheme} placement='top' title='カラーテーマを切り替える'>
                <IconButton aria-label='brightness_6' className={classes.iconbutton} style={{ color: color }}>
                  <Icon>brightness_6</Icon>
                </IconButton>
              </Tooltip>
            </div>
            */}
          </div>
        </div>

        <Divider className={classes.divider} style={{ backgroundColor: color }} />
        <List>
          <ListItem className={classes.placeListItemTitle} key='場所'>
            ルーム
          </ListItem>
          <ListItem
            button
            className={classes.placeListItemContents}
            key='全体'
            onClick={event => this.handleListItemClick(event, 0)}
            selected={room.rid === 0}
          >
            全体
          </ListItem>
          <ListItem
            button
            className={classes.placeListItemContents}
            key='会議室A'
            onClick={event => this.handleListItemClick(event, 1)}
            selected={room.rid === 1}
          >
            会議室A
          </ListItem>
          <ListItem
            button
            className={classes.placeListItemContents}
            key='会議室B'
            onClick={event => this.handleListItemClick(event, 2)}
            selected={room.rid === 2}
          >
            会議室B
          </ListItem>
          <ListItem
            button
            className={classes.placeListItemContents}
            key='会議室C'
            onClick={event => this.handleListItemClick(event, 3)}
            selected={room.rid === 3}
          >
            会議室C
          </ListItem>
        </List>
        <Divider className={classes.divider} style={{ backgroundColor: color }} />

        <List>
          <ListItem className={classes.userListItemTitle} key='メンバー'>
            メンバー
          </ListItem>
          <ListItem style={{ margin: '0', padding: '0', width: '100%' }}>
            <List className={classes.userList}>
              {
                Object.keys(statusclient.users).map(key => statusclient.users[key]).map(user => (
                  <ListItem button className={classes.userListItemContents} key={user.uuid} onClick={(() => this.handleOpenModal(user))}>
                    <div style={{ width: '100%' }}>
                      <div style={{ float: 'left' }}>
                        <div className={classes.userListItemName}>
                          {user.nickname === '' || user.nickname === null ? user.name : user.nickname}
                        </div>
                        <div className={classes.userListItemIsOnline}>
                          {(user.isOnline ? `${Room.getOnlineStatus(user.room)}` : 'オフライン')}
                        </div>
                      </div>
                      <div style={{ float: 'right' }}>
                        {NavBar.getTalkStatus(user)}
                      </div>
                    </div>
                  </ListItem>
                ))
              }
            </List>
          </ListItem>
        </List>
      </div>
    )
  }
}

NavBar.propTypes = {
  classes: PropTypes.object.isRequired,
  room: PropTypes.object.isRequired,
  sendMoveRoom: PropTypes.func.isRequired,
  statusclient: PropTypes.object.isRequired,
  webrtc: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  return {
    statusclient: state.statusclient,
    webrtc: state.webrtc,
    room: state.room
  }
}

export default compose(withStyles(styles), connect(mapStateToProps))(NavBar)

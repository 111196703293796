class Room {
  static getRoomName(rid) {
    if (rid === -1) {
      return ''
    }
    const roomnames = ['全体', '会議室A', '会議室B', '会議室C']
    return `${roomnames[rid]}`
  }

  static getOnlineStatus(rid) {
    if (rid === -1) {
      return 'オフライン'
    }
    const roomnames = ['全体', '会議室A', '会議室B', '会議室C']
    return `オンライン ${roomnames[rid]}`
  }
}

export default Room
